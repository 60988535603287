import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Autoplay, Pagination, Navigation } from 'swiper/modules';

import FAQ from './FAQ';

import './shweb.css';
import Android from './LOGO/Android.png';
import angular from './LOGO/angular.png';
import aspnet from './LOGO/aspnet.png';
import aws from './LOGO/aws.png';
import bootstrap from './LOGO/bootstrap.png';
import cpp from './LOGO/C++.png';
import c from './LOGO/c.png';
import digitalocean from './LOGO/digitalocean.png';
import css from './LOGO/CSS.png';
import django from './LOGO/django.png';
import flutter from './LOGO/flutter.png';
import GoogleCloud from './LOGO/GoogleCloud.png';
import html from './LOGO/HTML.png';
import ios from './LOGO/ios.png';
import java from './LOGO/Java.png';
import js from './LOGO/js.png';
import Laravel from './LOGO/Laravel.png';
import mern from './LOGO/MERN.png';
import python from './LOGO/python.png';
import react from './LOGO/react.png';
import Shopify from './LOGO/Shopify.png';
import sqlserver from './LOGO/sqlserver.png';
import swift from './LOGO/swift.png';
import vuejs from './LOGO/vuejs.png';
import WordPress from './LOGO/WordPress.png';
import php from './LOGO/php.png';
import mysql from './LOGO/mysql.png';
import oracle from './LOGO/oracle.png';
import zoho from './LOGO/zoho.png';
import linux from './LOGO/linux.png';
import cover from './img/cover.png';
import firebase from './LOGO/firebase.png';
import cs from './LOGO/cs.png';
 

import Adaptability from './icon/Adaptability.png';
import ClientCentricApproach from './icon/Client-CentricApproach.png';
import Collaboration from './icon/Collaboration.png';
import CostEffectiveness from './icon/Cost-Effectiveness.png';
import CustomerSatisfaction from './icon/CustomerSatisfaction.png';
import Innovation from './icon/Innovation.png';
import Quality from './icon/Quality.png';
import TimelyDelivery from './icon/TimelyDelivery.png';
import Transparency from './icon/Transparency.png';



const Home = () => {
  
    return (<>
<div className='my'><img src={cover} alt="Android" /></div>

<div className='introduction'>
  SynthesisHive, an evolving realm at the nexus of inspiration and innovation. Beyond the confines of traditional categorizations, SynthesisHive embodies a dynamic concept, a trajectory toward an unfolding future. As the driving force behind SynthesisHive, envision an invitation to partake in this odyssey of technological evolution.
</div>

<div className='servc' style={{ textAlign: 'center' }}><h1 className=''>Scope of Services</h1></div>
<div className='servc' style={{ textAlign: 'center' }}><p className=''>At SynthesisHive, we offer a wide range of services to </p></div>
<div className='servc' style={{ textAlign: 'center' }}><p className=''>meet your IT needs.</p></div>
    
<div className="servicesgallary">
  <div className="row row-cols-1 row-cols-md-3 g-4">
    <div className="col">
      <div className="card h-100 card-flip">
        <div className='card-front'>
          <div className="card-body">
            <h5 className="card-title">Web Development</h5>
            <p className="card-text">Explore cutting-edge web solutions tailored to your business needs, combining functionality, aesthetics, and seamless user experiences for optimal online presence.</p>
          </div>
        </div>
        <div className='card-back'>
          <div className="card-body wb1">
            <h5 className="card-title">Web Development</h5>
            <p className="card-text">Explore cutting-edge web solutions tailored to your business needs, combining functionality, aesthetics, and seamless user experiences for optimal online presence.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card h-100 card-flip">
        <div className='card-front'>
          <div className="card-body">
          <h5 className="card-title">App Development</h5>
          <p className="card-text">Elevate your brand with bespoke mobile applications, crafted to enhance user engagement, scalability, and performance across diverse platforms.</p>
          </div>
        </div>
        <div className='card-back'>
          <div className="card-body wb2">
          <h5 className="card-title">App Development</h5>
          <p className="card-text">Elevate your brand with bespoke mobile applications, crafted to enhance user engagement, scalability, and performance across diverse platforms.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card h-100 card-flip">
        <div className='card-front'>
          <div className="card-body">
          <h5 className="card-title">Software Development</h5>
          <p className="card-text">Unlock customized software solutions that align with your business objectives, employing the latest technologies for enhanced productivity and competitive advantage.</p>
          </div>
        </div>
        <div className='card-back'>
          <div className="card-body wb3">
          <h5 className="card-title">Software Development</h5>
          <p className="card-text">Unlock customized software solutions that align with your business objectives, employing the latest technologies for enhanced productivity and competitive advantage.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card h-100 card-flip">
        <div className='card-front'>
          <div className="card-body">
          <h5 className="card-title">UI & UX Design</h5>
          <p className="card-text">Immerse users in visually stunning interfaces and intuitive user experiences, blending creativity and functionality to captivate your audience and drive user satisfaction.</p>
          </div>
        </div>
        <div className='card-back'>
          <div className="card-body wb4">
          <h5 className="card-title">UI & UX Design</h5>
          <p className="card-text">Immerse users in visually stunning interfaces and intuitive user experiences, blending creativity and functionality to captivate your audience and drive user satisfaction.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card h-100 card-flip">
        <div className='card-front'>
          <div className="card-body">
          <h5 className="card-title">API Development</h5>
          <p className="card-text">Seamlessly integrate diverse systems with our robust API development services, fostering interoperability and efficiency within your digital ecosystem.</p>
          </div>
        </div>
        <div className='card-back'>
          <div className="card-body wb5">
          <h5 className="card-title">API Development</h5>
          <p className="card-text">Seamlessly integrate diverse systems with our robust API development services, fostering interoperability and efficiency within your digital ecosystem.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card h-100 card-flip">
        <div className='card-front'>
          <div className="card-body">
          <h5 className="card-title">Recruitment Process Outsourcing</h5>
          <p className="card-text">Optimize your workforce acquisition with our tailored recruitment process outsourcing solutions, ensuring a strategic and cost-effective approach to talent acquisition.</p>
          </div>
        </div>
        <div className='card-back'>
          <div className="card-body wb6">
          <h5 className="card-title">Recruitment Process Outsourcing</h5>
          <p className="card-text">Optimize your workforce acquisition with our tailored recruitment process outsourcing solutions, ensuring a strategic and cost-effective approach to talent acquisition.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card h-100 card-flip">
        <div className='card-front'>
          <div className="card-body">
          <h5 className="card-title">Digital Marketing</h5>
          <p className="card-text">Amplify your online presence with strategic digital marketing campaigns, leveraging SEO, social media, and content strategies to reach and engage your target audience effectively.</p>
          </div>
        </div>
        <div className='card-back'>
          <div className="card-body wb8">
          <h5 className="card-title">Digital Marketing</h5>
          <p className="card-text">Amplify your online presence with strategic digital marketing campaigns, leveraging SEO, social media, and content strategies to reach and engage your target audience effectively.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card h-100 card-flip">
        <div className='card-front'>
          <div className="card-body">
          <h5 className="card-title">Cloud Services</h5>
          <p className="card-text">Embrace the flexibility and scalability of cloud services tailored to your business, ensuring secure data management, reliable performance, and enhanced accessibility.</p>
          </div>
        </div>
        <div className='card-back'>
          <div className="card-body wb7">
          <h5 className="card-title">Cloud Services</h5>
          <p className="card-text">Embrace the flexibility and scalability of cloud services tailored to your business, ensuring secure data management, reliable performance, and enhanced accessibility.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card h-100 card-flip">
        <div className='card-front'>
          <div className="card-body">
          <h5 className="card-title">Quality Assurance and Testing</h5>
          <p className="card-text">QA and testing are essential in software development, ensuring reliable, user-friendly applications through systematic processes and rigorous testing for functionality and cybersecurity.</p>
          </div>
        </div>
        <div className='card-back'>
          <div className="card-body wb9">
          <h5 className="card-title">Quality Assurance and Testing</h5>
          <p className="card-text">QA and testing are essential in software development, ensuring reliable, user-friendly applications through systematic processes and rigorous testing for functionality and cybersecurity.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<br/> <br/>

<div  style={{ textAlign: 'center' }}><h1 className=''>Why Choose SynthesisHive ?</h1></div>
<>
  <Swiper
    effect={'coverflow'}
    grabCursor={true}
    centeredSlides={true}
    slidesPerView={'auto'}
    coverflowEffect={{rotate: 50, stretch: 0, depth: 100, modifier: 1, slideShadows: true,}}
    pagination={true}
    modules={[EffectCoverflow, Pagination]}
    className="mySwiper" >
    <SwiperSlide>
      <img src={Adaptability}  alt='ff'/>
      <div className='cntr-txt'><h6> Adaptability</h6></div>
    </SwiperSlide>
    <SwiperSlide>
      <img src={ClientCentricApproach} alt='ff' />
      <div className='cntr-txt'><h6>ClientCentricApproach</h6></div>
    </SwiperSlide>
    <SwiperSlide>
      <img src={Collaboration} alt='ff'/>
      <div className='cntr-txt'><h6>Collaboration</h6></div>
    </SwiperSlide>
    <SwiperSlide>
      <img src={CostEffectiveness} alt='ff'/>
      <div className='cntr-txt'><h6>CostEffectiveness</h6></div>
    </SwiperSlide>
    <SwiperSlide>
      <img src={CustomerSatisfaction} alt='ff'/>
      <div className='cntr-txt'><h6>CustomerSatisfaction</h6></div>
    </SwiperSlide>
    <SwiperSlide>
      <img src={Innovation} alt='ff'/>
      <div className='cntr-txt'><h6>Innovation</h6></div>
    </SwiperSlide>
    <SwiperSlide>
      <img src={Quality} alt='ff'/>
      <div className='cntr-txt'><h6>Quality</h6></div>
    </SwiperSlide>
    <SwiperSlide>
      <img src={TimelyDelivery} alt='ff'/>
      <div className='cntr-txt'><h6>TimelyDelivery</h6></div>
    </SwiperSlide>
    <SwiperSlide>
      <img src={Transparency} alt='ff'/>
      <div className='cntr-txt'><h6>Transparency</h6></div>
    </SwiperSlide>
  </Swiper>
</> 


<div className="vbn"><h1>Testimonials</h1></div>
    <div className='testimonial-menu'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{ delay: 2500, disableOnInteraction: false,}}
        pagination={{ clickable: true, }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper" >
        <SwiperSlide><div className='ttm'>I am thrilled with the outstanding services provided by 'SynthesisHive' in developing the website for my startup. Their exemplary work and dedication have brought immense joy to both me and my team. <br /><strong>Prashant K.</strong></div></SwiperSlide>
        <SwiperSlide><div className='ttm'>Gladly, I would like to express my appreciation for their top-quality, responsible, and timely services in developing the application. Their frequent follow-ups for any necessary fine adjustments and maintenance were commendable. I would certainly recommend their services to my friends and colleagues. <br /><strong>Faizan Najeeb</strong></div></SwiperSlide>
        <SwiperSlide><div className='ttm'>I highly recommend this exceptional app development firm to anyone seeking unparalleled app development services. The remarkable technical expertise, unwavering professionalism, and steadfast dedication to ensuring customer satisfaction set them apart as a standout choice in the industry. <br /><strong>Mehul Kapadia</strong></div></SwiperSlide>
      </Swiper>
    </div>

<div className='techweuse' style={{ textAlign: 'center' }}><h1 className=''>Technologies</h1></div>
<div className="tech-row">
  <div className="tech-column">
    <img src={Android} alt="sh-img" />
    <img src={angular} alt="sh-img" />
    <img src={aspnet} alt="sh-img" />
    <img src={aws} alt="sh-img" />
    <img src={bootstrap} alt="sh-img" />
    <img src={c} alt="sh-img" />
    <img src={cpp} alt="sh-img" />
    <img src={firebase} alt="sh-img" />
  </div>
  <div className="tech-column">
    <img src={vuejs} alt="sh-img" />
    <img src={WordPress} alt="sh-img" />
    <img src={css} alt="sh-img" />
    <img src={digitalocean} alt="sh-img" />
    <img src={django} alt="sh-img" />
    <img src={mern} alt="sh-img" />
    <img src={flutter} alt="sh-img" />
    <img src={GoogleCloud} alt="sh-img" />
  </div>
  <div className="tech-column">
    <img src={html} alt="sh-img" />
    <img src={ios} alt="sh-img" />
    <img src={java} alt="sh-img" />
    <img src={js} alt="sh-img" />
    <img src={Laravel} alt="sh-img" />
    <img src={php} alt="sh-img" />
    <img src={mysql} alt="sh-img" />
    <img src={oracle} alt="sh-img" />
  </div>
  <div className="tech-column">
    <img src={cs} alt="sh-img" />
    <img src={python} alt="sh-img" />
    <img src={react} alt="sh-img" />
    <img src={Shopify} alt="sh-img" />
    <img src={sqlserver} alt="sh-img" />
    <img src={swift} alt="sh-img" />
    <img src={zoho} alt="sh-img" />
    <img src={linux} alt="sh-img" />
  </div>
</div>
<FAQ></FAQ>
</>);};
  
export default Home;