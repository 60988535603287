import React, { useState } from 'react';

const Contact = () => {
  const [details, setDetails] = useState({
    fName: '',
    email: '',
    phoneNumber: '',
    subject: '',
  });

  const PostData = async (e) => {
    e.preventDefault();
    const { fName, email, phoneNumber, subject } = details;

    await fetch("https://synthesishive-987f7-default-rtdb.firebaseio.com/user.json", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fName, email, phoneNumber, subject,
      }),
    });

    alert('Thank you for your interest in SynthesisHive! We appreciate your inquiry and will respond to you as promptly as possible.');

    setDetails({
      fName: '',
      email: '',
      phoneNumber: '',
      subject: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (<>
  <div className="custom-box">
  <span className="custom-text1">CONTACT US</span>
  <span className="custom-text2">SynthesisHive</span>
</div>
<div className='contact-box'>
<div className='custom-g-box'>
  <div className="h-box1">Submit an enquiry</div>
  <div className="h-box2">Feel free to submit your details.</div>
  <div className="h-box2">Your details are 100% safe and confidential.</div>
  <div className="h-box3">Once you submit the contact information, our executives will get back to you soon.</div>
  <div className="h-box3">In case of connecting with the team you can email us on</div>
  <div className="h-box3">indo@SynthesisHive.com</div>
</div>
    <div className="custom-container">
      <form onSubmit={PostData}>
        <div className="custom-row">
          <div className="custom-col-25"><label htmlFor="fname">Name</label></div>
          <div className="custom-col-75"><input type="text" id="fname" name="fName" placeholder="Your first name..." value={details.fName} onChange={handleChange} required /></div>
        </div>
        <div className="custom-row">
          <div className="custom-col-25"><label htmlFor="feml">Email</label></div>
          <div className="custom-col-75"><input type="email" id="feml" name="email" placeholder="Your email address..." value={details.email} onChange={handleChange} required /></div>
        </div>
        <div className="custom-row">
          <div className="custom-col-25"><label htmlFor="fnum">Phone number</label></div>
          <div className="custom-col-75"><input type="text" id="fnum" name="phoneNumber" placeholder="Your phone number..." value={details.phoneNumber} onChange={handleChange} required /></div>
        </div>
        <div className="custom-row">
          <div className="custom-col-25">
            <label htmlFor="subject">Subject</label>
          </div>
          <div className="custom-col-75">
            <textarea id="subject" name="subject" placeholder="Write something.." value={details.subject} onChange={handleChange} required></textarea>
          </div>
        </div>
        <div className="custom-row type-submit">
          <input type="submit" value="Submit"/>
        </div>
      </form>
    </div>
    </div>
  </>);
};

export default Contact;