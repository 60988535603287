import devimg from './img/devimg.png';
import mobdev3 from './img/mobdev3.png';
import bckd from './img/bckd.png';
import webdev2 from './img/webdev2.png';
import webdev from './img/webdev.png';

const ServiceCard = ({ title, description, imageSrc, isImageOnLeft }) => {
  const containerClass = isImageOnLeft ? 'box img-left' : 'box img-right';
  return (
    <div className={containerClass}>
      <div className="content">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="image"><img src={imageSrc} alt={`${title}`} /></div>
    </div>
  );
};

const CodeDesign = () => {
  return (
    <div>
      <div className="container">
        <ServiceCard
          title="Web Development"
          description="Our web development services go beyond creating websites; we craft digital experiences. From responsive designs to feature-rich functionality, we leverage the latest technologies to ensure your online presence stands out."
          imageSrc={webdev}
          isImageOnLeft={true}
        />

        <ServiceCard
          title="App Development"
          description="Elevate your business with our bespoke mobile app development services. We specialize in creating intuitive and high-performance applications across iOS and Android platforms."
          imageSrc={mobdev3}
          isImageOnLeft={false}
        />

        <ServiceCard
          title="Software Development"
          description="Harness the power of customized software solutions to drive your business forward. Our software development services encompass a wide range of applications, from enterprise solutions to specialized tools. We employ the latest technologies to create scalable, secure, and robust software that aligns seamlessly with your organizational goals."
          imageSrc={bckd}
          isImageOnLeft={true}
        />

        <ServiceCard
          title="UI & UX Design"
          description="Immerse your audience in visually stunning interfaces and seamless user experiences. Our UI & UX design services focus on creating designs that not only look impressive but also enhance user engagement. We combine creativity with usability, ensuring your digital products not only meet industry standards but also leave a lasting positive impression on your users."
          imageSrc={webdev2}
          isImageOnLeft={false}
        />

        <ServiceCard
          title="API Development"
          description="Seamlessly connect your systems and applications with our robust API development services. We specialize in creating APIs that facilitate data exchange, enhance interoperability, and streamline processes. Whether you're integrating third-party services or building a comprehensive ecosystem, our API solutions are designed to meet the demands of modern businesses."
          imageSrc={devimg}
          isImageOnLeft={true}
        />

      </div>
    </div>
  );
};

export default CodeDesign;
