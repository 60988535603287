import React, { useState } from 'react';

function Footer(){
  const [emaildetails, setemailDetails] = useState({
    email: '',
  });

  const SendMail = async (e) => {
    e.preventDefault();
    const { email } = emaildetails;
    const res = await fetch("https://synthesishive-987f7-default-rtdb.firebaseio.com/user.json", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email
      }),
    });

    if (res.ok) {
      window.alert('Thank you for subscribing to our newsletter! Stay tuned for exciting updates and exclusive content.');
      setemailDetails({
        email: '',
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setemailDetails((prevemailDetails) => ({
      ...prevemailDetails,
      [name]: value,
    }));
  };
  
    return(<>
      <footer className="text-center text-lg-start bg-body-tertiary text-muted">

      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="fw-bold mb-4 footer-sh">SynthesisHive</h6>
              <p>Navigating business orbits, SynthesisHive harnesses the energy of efficiency, creating a gravitational pull towards success in the corporate universe.</p>
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 footer-menu">
              <h6 className="text-uppercase fw-bold mb-4">QUICK ACCESS</h6>
              <p><a href="/" className="text-reset">Home</a></p>
              <p><a href="/code&design" className="text-reset">Code&Design</a></p>
              <p><a href="/rpo" className="text-reset">RPO</a></p>
              <p><a href="/cloudservices" className="text-reset">Cloud Services</a></p>
              <p><a href="/contact" className="text-reset">Contact Us</a></p>
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 footer-menu">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
              <p><a href="/code&design" className="text-reset"> Web Development</a></p>
              <p><a href="/code&design" className="text-reset">App Development</a></p>
              <p><a href="/code&design" className="text-reset">Software Development</a></p>
              <p><a href="/code&design" className="text-reset">UI/UX Design</a></p>
              <p><a href="/code&design" className="text-reset">API Development</a></p>
              <p><a href="/rpo" className="text-reset">RPO Services</a></p>
              <p><a href="/cloudservices" className="text-reset">Cloud Services</a></p>
              <p><a href="/code&design" className="text-reset">UI/UX Design</a></p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              
          <form onSubmit={SendMail}>
  <div className="row d-flex justify-content-center">
    <div className="col-auto">
      <p className="pt-2"><strong>Sign up for our newsletter</strong></p>
    </div>
    <div className="">
      <div data-mdb-input-init className="form-outline mb-4">
        <input type="email" name='email' className="form-control" placeholder="Email Address" value={emaildetails.email} onChange={handleChange} required />
      </div>
    </div>
    <div className="col-auto">
      <button data-mdb-ripple-init type="submit" className="btn btn-primary mb-4">Subscribe</button>
    </div>
  </div>
</form>
          <a href="mailto:info@synthesishive.com" target='blank' className='eml'>
          <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/></svg> info@synthesishive.com</p></a>
        </div>
      </div>
    </div>
  </section>
  <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>© 2024 Copyright:
    <a className="text-reset fw-bold" href="https://synthesishive.com/">SynthesisHive.com</a>
  </div>
</footer>
  
    
</>)}

export default Footer;