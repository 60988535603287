import { Outlet, Link } from "react-router-dom";

const Navbar = () => {
  return (<>

<nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <a className="navbar-brand" href="/">SynthesisHive</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Code & Design</a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="/code&design">UI/UX Design</Link></li>
            <li><Link className="dropdown-item" to="/code&design">API Development</Link></li>
            <li><Link className="dropdown-item" to="/code&design">Web Development</Link></li>
            <li><Link className="dropdown-item" to="/code&design">App Development</Link></li>
            <li><Link className="dropdown-item" to="/code&design">Software Development</Link></li>
          </ul>
        </li>
        <li className="nav-item"><Link className="nav-link" to="/rpo">RPO</Link></li>
        <li className="nav-item"><Link className="nav-link" to="/cloudservices">Cloud Services</Link></li>
        <li className="nav-item"><Link className="nav-link" to="/contact">Contact Us</Link></li>
      </ul>
    </div>
  </div>
</nav>



      <Outlet />
    </>
  )
};

export default Navbar;