import cloud from './img/cloud.png';
import db from './img/db.png';
import mobdev8 from './img/mobdev8.png';

const ServiceCard = ({ title, description, imageSrc, isImageOnLeft }) => {
  return (<>
    <div className="box img-left cloudser">
      <div className="content">
        <h2>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: description }} /></div>
        <div className="image"><img src={imageSrc} alt={`${title}`} /></div>
      </div>
    </>);
  };

const CloudServices = () => {
  return (<>
    <div className="container">
      <ServiceCard
        title="Server & OS Management"
        description="<ul><li>Efficient server and operating system (OS) management for seamless tech infrastructure operation.</li><li>Comprehensive services provided by our expert team.</li><li>Handling complexities of server configuration, optimization, and maintenance.</li><li>Entrust us with these responsibilities, allowing you to focus on core business activities.</li><li>We ensure your servers and operating systems run at peak performance.</li></ul>"
        imageSrc={mobdev8} />
      <ServiceCard
        title="Cloud Platforms"
        description="<ul>
          <li>Embrace the power of cloud computing with our cutting-edge services.</li>
          <li>Offering scalable and flexible solutions tailored to your tech company's unique needs.</li>
          <li>Choose from Infrastructure as a Service (IaaS), Platform as a Service (PaaS), or Software as a Service (SaaS).</li>
          <li>Designed to enhance agility, reduce costs, and drive innovation.</li>
          <li>Experience the freedom to scale resources dynamically, staying ahead in the competitive tech landscape.</li>
        </ul>"
        imageSrc={cloud} />
      <ServiceCard
        title="Database and Application Server"
        description="<ul>
          <li>Reliable management fundamental to the success of any tech company.</li>
          <li>Specializing in optimizing database performance and ensuring data integrity.</li>
          <li>Expertise in managing application servers for optimal functionality.</li>
          <li>Services include database administration, data migration, and application server configuration.</li>
          <li>Trust us to handle technical intricacies, allowing you to focus on achieving business goals.</li>
        </ul>"
        imageSrc={db} />
    </div>

    <div className="cloudser">
      <p>At SynthesisHive, we understand the importance of a robust and secure cloud infrastructure. Our Cloud Services are designed to empower your business, providing the technological foundation needed for growth and innovation. Contact us today to explore how our tailored solutions can elevate your tech capabilities.</p>
    </div>
    </>);
  };
  
export default CloudServices;