import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import Home from "./Home";
import Contact from "./Contact";
import NoPage from "./NoPage";
import CodeDesign from "./CodeDesign";
import Rpo from "./Rpo";
import CloudServices from "./CloudServices";

function Layout() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="code&design" element={<CodeDesign />} />
          <Route path="rpo" element={<Rpo />} />
          <Route path="cloudservices" element={<CloudServices />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Layout;