import React, { useState, useEffect } from 'react';

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(0); 

  const questions = [
    'What services do you offer?',
    'How can I contact your support team?',
    'Do you provide ongoing maintenance and support?',
    'How long does it take to complete a typical IT project?',
    'Are your solutions compatible with mobile devices?',
  ];

  const answers = [
    'We provide a diverse range of services tailored to meet your specific requirements. Our team of experts is consistently available to deliver optimal solutions for your needs.',
    'Our dedicated support team is readily available to assist you via email at info@synthesishive.com.  .',
    'Yes, we offer ongoing maintenance and support to ensure the smooth operation of your IT systems.',
    'The timeline for a project depends on its complexity, but we strive to deliver timely results.',
    'Yes, our solutions are meticulously designed to be compatible, ensuring seamless performance across all platforms.',
  ];

  useEffect(() => {
    // Update the selected question to the first one when the component mounts
    setSelectedQuestion(0);
  }, []);

  return (<div className='faq-div'>
  <div  style={{ textAlign: 'center', margin: '50px' }}><h1 className=''>Frequently Asked Questions</h1></div>
  <div className='section-faq'>
    <div className="contact-container">
      <div className="question-container">
        {questions.map((question, index) => (
          <div
            key={index}
            onClick={() => setSelectedQuestion(index)}
            className={`question ${selectedQuestion === index ? 'selected' : ''}`}
          >
            {question}
          </div>
        ))}
      </div>
      <div className="answer-container">
        {selectedQuestion !== null && (
          <div className="answer" style={{ height: '100%' }}>
            {answers[selectedQuestion]}
          </div>
        )}
      </div>
    </div>
    
    <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      What services do you offer?
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      We provide a diverse range of services tailored to meet your specific requirements. Our team of experts is consistently available to deliver optimal solutions for your needs.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      How can I contact your support team?
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      Our dedicated support team is readily available to assist you via email at info@synthesishive.com. .
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Do you provide ongoing maintenance and support?
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      Yes, we offer ongoing maintenance and support to ensure the smooth operation of your IT systems.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      How long does it take to complete a typical IT project?
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      The timeline for a project depends on its complexity, but we strive to deliver timely results.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      Are your solutions compatible with mobile devices?
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      Yes, our solutions are meticulously designed to be compatible, ensuring seamless performance across all platforms.
      </div>
    </div>
  </div>
</div>
    </div>
    </div>);
};

export default FAQ;
