import './App.css';
import Layout from "./Components/Layout"
import Footer from './Components/Footer';

function App() {
  return (<>
    <Layout></Layout>
    <Footer></Footer>
    </>);
}

export default App;